// https://mattfarley.ca/ for containers and put what I worked on 

const PortfolioIntro = () => {
    return (
    <section className="container is-medium">
        <div className="box has-text-centered has-text-black is-three-fifths is-offset-one-fifth">
            <h1>My Recent Work</h1>
            <p>Here are a few projects I've worked on. Interested in learning more? <a href="mailto: cara.ottmar@gmail.com">Email Me</a></p>
        </div>
    </section>
    )
}

export default PortfolioIntro;